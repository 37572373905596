import { mapMutations, mapState, mapGetters } from 'vuex';
import { fetchGroups, fetchSchoolCycleByEI } from '../../../configuration/helpers/KnGroupsOptions';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTable from '../../../shared/components/KnTable.vue';
import { canAdd, canDelete } from '../../../shared/helpers/permissionsUtils';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import { fetchSyllabus } from '../../helpers/syllabusOptions';
import { getItem } from '../../../../api/api-methods';
export default {
  components: { KnLocalAlert, KnTable },
  mixins: [paginationMixin],
  data() {
    return {
      headers: [
        {
          text: 'Grupo',
          value: 'nombre_grupo',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Salon de clases',
          value: 'salon_clases.nombre_salon',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'No. de alumnos',
          value: 'alumnos',
          class: 'purple--text',
        },
        {
          text: 'Ciclo escolar',
          value: 'ciclo',
          class: 'purple--text',
        },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          class: 'purple--text',
        },
      ],
      items: [],
      inactiveItems: [],
      //search: null,
      showInactive: false,
      loading: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      schoolCycle: [],
      syllabus: [],
      syllabusById: null,
      educationlevels: [],
      educationlevelById: null,
      schoolCycleById: null,
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      //alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedGroups(1);
    //Ciclo escolar
    const resSchoolCycles = await fetchSchoolCycleByEI(this.institutionId);
    this.schoolCycle = resSchoolCycles.results ? resSchoolCycles.results : [];
    //Plan de estudios
    const { ok, data } = await fetchSyllabus({
      institutionId: this.institutionId,
      systemStatus: true,
    });
    this.syllabus = ok ? data : [];
    //Nivel educativo
    const educationlevelsRes = await getItem(
      `/app-personas/filters/nivel-educativo?limit=15&estatus_sistema=${true}&institucion_educativa=${
        this.institutionId
      }`
    );
    this.educationlevels = educationlevelsRes.results;

  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search', 'clearSearch']),
    ...mapGetters(['isTeacherEmploye', 'employeeId']),
    processedItems() {
      return this.items.map((item) => {
        return {
          ...item,
          'ciclo_escolar.dato':
            item['ciclo_escolar.dato'] === ''
              ? 'Otro dato'
              : item['ciclo_escolar.dato'],
        };
      });
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedGroups(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedGroups(1);
      },
    },
    schoolCycleById: {
      async handler(){
        await this.getPaginatedGroups(1);
      }
    },
    educationlevelById: {
      async handler(){
        await this.getPaginatedGroups(1);
      }
    },
    syllabusById: {
      async handler(){
        await this.getPaginatedGroups(1);
      }
    }
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    canAdd: canAdd,
    canDelete: canDelete,
    buttomActionText() {
      return this.showInactive ? 'Ver alumnos' : '';
    },
    async getPaginatedGroups(page = 1) {
      try {
        this.items = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          if (this.isTeacherEmploye === true && this.employeeId !== null) {
            const { ok, data, message, count } = await fetchGroups({
              institutionId: this.institutionId,
              systemStatus: !this.showInactive,
              profesores: this.employeeId,
              ciclo_escolar: this.schoolCycleById !== null ? this.schoolCycleById : null,
              plan_estudios: this.syllabusById !== null ? this.syllabusById : null,
              nivel_educativo: this.educationlevelById !== null ? this.educationlevelById : null,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });

            if (ok) {
              this.setPaginationCount(count);

              for (const group of data) {
                this.items.push({
                  ...group,
                  foto: group.imagen_grupo ? group.imagen_grupo.imagen : null,
                  nombre: group.nombre_grupo,
                  alumnos: group.alumnos.length,
                  ciclo: group.ciclo_escolar
                    ? group.ciclo_escolar.dato
                    : 'Estimulación temprana',
                });
              }
            } else {
              console.error('No se pudieron obtener los grupos', message);
            }

            this.loading = false;
          } else{
            const { ok, data, message, count } = await fetchGroups({
              institutionId: this.institutionId,
              systemStatus: !this.showInactive,
              ciclo_escolar: this.schoolCycleById !== null ? this.schoolCycleById : null,
              plan_estudios: this.syllabusById !== null ? this.syllabusById : null,
              nivel_educativo: this.educationlevelById !== null ? this.educationlevelById : null,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });

            if (ok) {
              this.setPaginationCount(count);

              for (const group of data) {
                this.items.push({
                  ...group,
                  foto: group.imagen_grupo ? group.imagen_grupo.imagen : null,
                  nombre: group.nombre_grupo,
                  alumnos: group.alumnos.length,
                  ciclo: group.ciclo_escolar
                    ? group.ciclo_escolar.dato
                    : 'Estimulación temprana',
                });
              }
            } else {
              console.error('No se pudieron obtener los grupos', message);
            }

            this.loading = false;
          }
        }
      } catch (error) {
        console.error('Error al obtener los grupos:', error);
        this.loading = false;
      }
    },
    async action2() {
      if (this.showInactive) {
        this.$router.push({ name: 'About' });
        //await this.enableEmployee(supplierId);
      } else {
        //await this.disableEmployee(supplierId);
      }
    },
  },
};
